import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
//import FloatingActions from "../../ui/FloatingActions";
import { Add, ArrowBack, Delete, ExpandMore } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { account, common, confirm, form, errors } from "../../messages";
import { SLOT_VALUES } from "../../utils/gdspConstants";
import NumberInput from "../../ui/NumberInput";
import { TimePicker } from "../../ui/DatePicker";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { format, parse } from "date-fns";
import styles from "../../utils/styles";
import Confirm from "../../ui/Confirm";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import FloatingActions from "../../ui/FloatingActions";

const getDateText = (date) => {
  const formatDate = date.split("-");
  date = `${formatDate[2]}/${formatDate[1]}/${formatDate[0]}`;
  return date;
};

const byName = (a, b) => a.name.localeCompare(b.name);

function ConfList({
  confList,
  fairList,
  onDelete,
  onAction,
  openConfig,
  tabFair,
  deleteRequest,
  setDeleteRequest,
  fairId,
  handleClose,
}) {
  const classes = styles.useStyles();
  const intl = useIntl();
  const history = useHistory();

  const [fairFilter, setFairFilter] = React.useState(fairList[0]);
  const [confToDelete, setConfToDelete] = React.useState(null);

  const filteredConf = confList.filter(
    (conf) => conf.content_id === (fairId || fairFilter?.id)
  );

  const goToHome = () => {
    history.push(MANAGER_ROUTES.HOME_MENU);
  };

  const getTimeText = (time) => {
    return time.slice(0, 2) + ":" + time.slice(2);
  };

  const getFormattedTime = (time) => {
    const newDate = format(new Date(), "dd/MM/yyyy");
    const newTime = time.slice(0, 2) + ":" + time.slice(2);
    const dateTime = newDate + " " + newTime;
    return parse(dateTime, "dd/MM/yyyy HH:mm", new Date());
  };

  const formatConf = (conf, clone) => {
    // format conf obj for bmapi
    const formatTimeSlots = conf.time_slots.map((item) => {
      return {
        start: getFormattedTime(item.start),
        end: getFormattedTime(item.end),
      };
    });

    let formattedConf = {
      ...conf,
      production_capacity: conf.time_slots[0].production_capacity,
      // production_capacity has the same value in every slots
      time_slots: formatTimeSlots,
    };

    const selectedFair = fairList.find(
      (f) => f.id === formattedConf.content_id
    );

    // remove existing day from dates array for cloning on other available days
    if (clone) {
      let filteredSelectedFair = { ...selectedFair };
      filteredSelectedFair.dates = filteredSelectedFair.dates.filter(
        (data) => data !== formattedConf.day
      );
      onAction(formattedConf, filteredSelectedFair);
    } else {
      onAction(formattedConf, selectedFair, "edit");
    }
  };

  const handleDelete = (c) => {
    setDeleteRequest(true);
    setConfToDelete(c);
  };

  console.log("ffffffffffff ", fairList);

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      {deleteRequest && (
        <Confirm
          open={!!deleteRequest}
          onConfirm={() => onDelete(confToDelete)}
          onCancel={() => setDeleteRequest(false)}
          text={intl.formatMessage(confirm.deleteElement)}
        />
      )}

      {!tabFair && (
        <TextField
          margin="normal"
          select
          fullWidth
          label="Fiera"
          value={fairFilter}
          onChange={(e) => setFairFilter(e.target.value)}
        >
          {fairList.map((option) => (
            <MenuItem key={option.id} value={option}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      )}

      <Box mt={2}>
        {filteredConf.length > 0 ? (
          filteredConf.map((c) => (
            <Accordion key={c.id}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>{getDateText(c.day)}</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Typography
                    gutterBottom
                    variant="body1"
                    className={classes.disabledText}
                  >
                    Durata slot: {c.time_slot_value} minuti
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body1"
                    className={classes.disabledText}
                  >
                    Num. max prenotazioni per slot:{" "}
                    {c.time_slots[0].production_capacity}
                    {/* production_capacity has the same value in every slots */}
                  </Typography>
                  {c.time_slots.map((slot) => (
                    <>
                      <TextField
                        margin="normal"
                        label="Orario di inizio"
                        value={getTimeText(slot.start)}
                        disabled
                      />
                      <TextField
                        margin="normal"
                        label="Orario di fine"
                        value={getTimeText(slot.end)}
                        disabled
                      />
                    </>
                  ))}
                </Box>
              </AccordionDetails>
              <Divider />
              <AccordionActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => handleDelete(c)}
                >
                  Elimina
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => formatConf(c, 1)}
                  disabled={
                    (fairList.find((f) => f.id === fairId) ||
                      fairFilter?.dates.length) === filteredConf.length
                  }
                >
                  Duplica
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => formatConf(c, 0)}
                >
                  Modifica
                </Button>
              </AccordionActions>
            </Accordion>
          ))
        ) : (
          <Typography gutterBottom>Nessuna configurazione inserita.</Typography>
        )}
      </Box>

      {!tabFair ? (
        <>
          <Box my={2}>
            <Button onClick={goToHome} startIcon={<ArrowBack />}>
              {intl.formatMessage(common.backHome)}
            </Button>
          </Box>
          <FloatingActions>
            <Fab
              variant="extended"
              color="primary"
              onClick={() => openConfig(fairFilter)}
            >
              <Add />
              {intl.formatMessage(form.configAdd)}
            </Fab>
          </FloatingActions>
        </>
      ) : (
        <Box mt={5} mb={1} style={{ float: "right" }}>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            {intl.formatMessage(common.close)}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 10 }}
            onClick={() => openConfig(fairList.find((f) => f.id === fairId))}
          >
            {intl.formatMessage(form.configAdd)}
          </Button>
        </Box>
      )}
    </Container>
  );
}

const getEmpyTimeSlot = (arr) =>
  !arr || arr.length === 0 ? [{ start: null, end: null }] : arr;

const initialValues = (conf = {}) => ({
  content_id: conf.content_id || "",
  day: conf.day || "",
  id: conf.id || "",
  inactive: conf.inactive || false,
  time_slot_value: conf.time_slot_value || 0,
  production_capacity: conf.production_capacity || "",
  over_production: conf.over_production || 0,
  time_slots: getEmpyTimeSlot(conf.time_slots) || getEmpyTimeSlot(null),
});

function getEventDays(start_date, expiration_date) {
  // Parse the API-provided UTC strings into Date objects
  const startDate = new Date(start_date);
  const expirationDate = new Date(expiration_date);

  // If start_date is greater than expiration_date, return an empty array
  if (startDate > expirationDate) {
    return [];
  }

  const daysArray = [];
  const currentDate = new Date(startDate);

  while (currentDate <= expirationDate) {
    // Format the current date as YYYY-MM-DD in the browser's local time zone
    daysArray.push(formatLocalDate(currentDate));
    // Move to the next day in the local time zone
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return daysArray;
}

// Helper function to format a Date object to YYYY-MM-DD in local time
function formatLocalDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export default function ReservationsConfig({
  tabFair,
  bsFair,
  fairId,
  handleClose,
}) {
  const intl = useIntl();
  const {
    bmapi,
    notifyError,
    startLoading,
    stopLoading,
    notifySuccess,
  } = useBmapi();
  const bs = bsFair || bmapi.getUserInfo().business.id;

  const [values, setValues] = useState(initialValues());
  const [openDialog, setOpenDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [clone, setClone] = useState(false);
  const [deleteRequest, setDeleteRequest] = React.useState(false);
  const [fairList, setFairList] = useState(null);
  const [fair, setFair] = useState(null);
  const [confList, setConfList] = React.useState(null);
  const [dates, setDates] = React.useState("");

  console.log("ffffff", fair);

  const loadConfig = useCallback(() => {
    if (bmapi) {
      bmapi
        .getEcomConf({
          business: bs,
        })
        .then((resp) => {
          if (!resp) {
            resp = [];
          }
          setConfList(resp);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  }, [bmapi, intl, notifyError, bs]);

  const loadFairs = useCallback(() => {
    startLoading();
    if (bmapi) {
      if (bmapi.settings?.reservation?.type === "event-campaign") {
        bmapi
          .getBusinessCampaigns({})
          .then((resp) => {
            if (!resp) {
              resp = [];
            } else {
              resp = resp.sort(byName).map((c) => ({
                //...c,
                name: c?.campaign_data?.name,
                id: c?.campaign_data?.id,
                dates: getEventDays(c.start_date, c.expiration_date),
              }));
              console.log("eeeee", resp);
              setFairList(resp);
            }
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
          })
          .finally(() => {
            stopLoading();
          });
      } else {
        bmapi
          .getFairsDates({ type: "FAIR", participant: bsFair ? "" : bs })
          .then((resp) => {
            if (!resp) {
              resp = [];
            } else {
              resp.sort(byName);
              setFairList(resp);
            }
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
          })
          .finally(() => {
            stopLoading();
          });
      }
    }
  }, [bmapi, intl, notifyError, startLoading, stopLoading, bs, bsFair]);

  useEffect(() => {
    loadConfig();
    loadFairs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadFairs, loadConfig]);

  const getFormattedTimeSlots = useCallback(() => {
    // time_slots: [{start: "", end: "", production_capacity: ""}] for bmapi
    let confTimeSlots = [];

    for (let i = 0; i < values.time_slots.length; i++) {
      if (values.time_slots[i].start && values.time_slots[i].end) {
        let timeslots = {};
        timeslots.start = format(values.time_slots[i].start, "HHmm");
        timeslots.end = format(values.time_slots[i].end, "HHmm");
        timeslots.production_capacity = +values.production_capacity;
        confTimeSlots.push(timeslots);
      }
    }
    return confTimeSlots;
  }, [values]);

  const getFormattedObj = useCallback(
    (i) => {
      let conf = {};
      conf.day = dates[i] || values.day;
      conf.id = clone ? "" : values.id || "";
      conf.content_id = fair.id;
      conf.inactive = false;
      conf.time_slot_value = +values.time_slot_value;
      conf.time_slots = getFormattedTimeSlots();

      return conf;
    },
    [dates, fair, values, getFormattedTimeSlots, clone]
  );

  const handleValue = useCallback((label) => {
    const updateValue = (val) => {
      setValues((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  const handleTimeValue = useCallback(
    (label, index) => (e) => {
      const value = e;
      const newValues = { ...values };
      newValues.time_slots[index][label] = value;
      setValues(newValues);
    },
    [values]
  );

  const handleAddTime = useCallback(
    (index) => () => {
      const newValues = { ...values };
      newValues.time_slots.splice(index + 1, 0, {
        start: null,
        end: null,
      });
      setValues(newValues);
    },
    [values]
  );

  const handleDeleteTime = useCallback(
    (index) => () => {
      const newValues = { ...values };
      newValues.time_slots.splice(index, 1);
      if (!newValues.time_slots.length) {
        newValues.time_slots.push({ start: null, end: null });
      }
      setValues(newValues);
    },
    [values]
  );

  const handleToggle = (value) => () => {
    const currentIndex = dates.indexOf(value);
    const newChecked = [...dates];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setDates(newChecked);
  };

  const openConfig = (fair) => {
    fair && setFair(fair); // fair light
    setOpenDialog(true);
  };

  const closeConfig = () => {
    setOpenDialog(false);
    setEdit(false);
    setClone(false);
    setFair(null);
    setDates("");
    setValues(initialValues());
  };

  const onDelete = (conf) => {
    startLoading();
    bmapi
      .deleteEcomConf(conf.id, bs)
      .then(() => {
        setDeleteRequest(false);
        notifySuccess(intl.formatMessage(common.deleteElement));
      })
      .then(() => loadConfig())
      .catch((e) => {
        setDeleteRequest(false);
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  const onAction = (conf, fair, action) => {
    action === "edit" ? setEdit(true) : setClone(true);
    setFair(fair);
    openConfig();
    setValues(initialValues(conf));
  };

  const update = useCallback(
    (c) => {
      // format conf obj for bmapi
      const conf = getFormattedObj();

      // check if a time range is selected (start - end)
      if (!conf.time_slots.length) {
        notifyError(
          "È obbligatorio indicare almeno un orario di inizio e un orario di fine."
        );
      } else {
        startLoading();

        bmapi
          .updateEcomConf(c || conf, bs)
          .then(() => {
            notifySuccess(intl.formatMessage(account.saveConfirm));
            closeConfig();
          })
          .then(() => loadConfig())
          .catch((e) => {
            if (e.message === "range must be a multiple of time slot value") {
              notifyError(
                "Si è verificato un errore. Gli orari scelti non sono compatibili con la durata dello slot."
              );
            } else if (
              e.message ===
              "start time cannot be equal or greater than end time"
            ) {
              notifyError(intl.formatMessage(errors.equalTime));
            } else notifyError(getErrorMessageString(e, intl));
          })
          .finally(() => {
            stopLoading();
          });
      }
    },
    [
      bmapi,
      intl,
      loadConfig,
      notifyError,
      notifySuccess,
      startLoading,
      stopLoading,
      getFormattedObj,
      bs,
    ]
  );

  const submit = (e) => {
    e.preventDefault();

    if (!edit) {
      let confs = [];

      for (let i = 0; i < dates.length; i++) {
        // format conf obj for bmapi for selected dates
        let conf = getFormattedObj(i);
        confs.push(conf);
      }

      if (!confs.length) {
        // check if at list one day is checked
        notifyError(
          "È obbligatorio indicare almeno un giorno per impostare la configurazione."
        );
      } else if (!confs[0].time_slots.length) {
        // check if a time range is selected (start - end)
        notifyError(
          "È obbligatorio indicare almeno un orario di inizio e un orario di fine."
        );
      } else {
        startLoading();
        // filter from configurations list based on selected fair.
        // obtain array of dates to compare and decide if the configuration is new,
        // or if already exists for a certain day and needs to be modified.
        const fairSelectedConfs = confList.filter(
          (conf) => conf.content_id === fair.id
        );
        const days = fairSelectedConfs.map((obj) => obj.day);

        const confToUpdate = confs.filter((obj) => days.includes(obj.day));
        const confToCreate = confs.filter((obj) => !days.includes(obj.day));

        // assign existing conf id to the new conf for the same day, so it is edit and not create.
        for (let i = 0; i < confToUpdate.length; i++) {
          const id = fairSelectedConfs.find(
            (fair) => fair.day === confToUpdate[i].day
          ).id;

          confToUpdate[i].id = id;
        }

        // check is there are conf to edit
        confToUpdate.length > 0 && confToUpdate.map((c) => update(c));

        // check is there are conf to create
        confToCreate.length > 0 &&
          bmapi
            .createEcomConf({ confs: confToCreate }, bs)
            .then(() => {
              notifySuccess(intl.formatMessage(account.saveConfirm));
              closeConfig();
            })
            .then(() => loadConfig())
            .catch((e) => {
              if (e.message === "range must be a multiple of time slot value") {
                notifyError(
                  "Si è verificato un errore. Gli orari scelti non sono compatibili con la durata dello slot."
                );
              } else if (
                e.message ===
                "start time cannot be equal or greater than end time"
              ) {
                notifyError(intl.formatMessage(errors.equalTime));
              } else notifyError(getErrorMessageString(e, intl));
            })
            .finally(() => {
              stopLoading();
            });
      }
    } else {
      // edit single configuration
      update();
    }
  };

  return (
    <Container maxWidth="sm">
      <Dialog open={openDialog} onClose={closeConfig} fullWidth>
        <DialogTitle>
          {!edit ? "Aggiungi configurazione" : "Modifica configurazione"}
        </DialogTitle>
        <form onSubmit={submit}>
          <DialogContent>
            <TextField
              margin="normal"
              fullWidth
              label="Fiera"
              value={fair?.name}
              disabled={fair}
            />
            {fair && (
              <React.Fragment>
                <FormControl margin="normal" required>
                  <FormLabel>Durata slot (minuti)</FormLabel>
                  <RadioGroup
                    row
                    name="slot_value"
                    value={+values.time_slot_value}
                    onChange={handleValue("time_slot_value")}
                  >
                    {SLOT_VALUES.map((time) => (
                      <FormControlLabel
                        key={time}
                        value={time}
                        control={
                          <Radio required size="small" color="primary" />
                        }
                        label={time}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                {values.time_slots.map((time, i) => (
                  <React.Fragment key={i}>
                    <List style={{ padding: 0 }}>
                      <TimePicker
                        style={{ marginTop: 0 }}
                        label={intl.formatMessage(common.from)}
                        value={time.start}
                        onChange={handleTimeValue("start", i)}
                        clearable
                        required
                      />
                      <TimePicker
                        style={{ marginTop: 0 }}
                        label={intl.formatMessage(common.to)}
                        value={time.end}
                        onChange={handleTimeValue("end", i)}
                        clearable
                        required
                      />
                      <ListItemSecondaryAction>
                        <IconButton onClick={handleAddTime(i)}>
                          <Add />
                        </IconButton>
                        <IconButton onClick={handleDeleteTime(i)}>
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </List>
                  </React.Fragment>
                ))}
                <NumberInput
                  margin="dense"
                  float
                  name="reservations_quantity"
                  label="Num. max prenotazioni per slot"
                  value={values.production_capacity}
                  onChange={handleValue("production_capacity")}
                  fullWidth
                  required
                />
                {!edit && (
                  <Box mt={2}>
                    <List>
                      <Typography variant="h6">
                        Indica i giorni per i quali vuoi impostare la
                        configurazione
                      </Typography>
                      {fair.dates.map((date) => {
                        const labelId = date;

                        return (
                          <ListItem
                            key={date}
                            dense
                            button
                            onClick={handleToggle(date)}
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={dates.indexOf(date) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={labelId}
                              primary={getDateText(date)}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                )}
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions style={{ marginTop: 20 }}>
            <Button variant="outlined" color="secondary" onClick={closeConfig}>
              {intl.formatMessage(common.cancel)}
            </Button>
            <Button variant="contained" color="primary" type="submit">
              {intl.formatMessage(common.confirm)}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {confList &&
        fairList &&
        !edit &&
        (!fairList.length && !bsFair ? (
          <Typography gutterBottom>
            Il merchant non è abilitato ad alcuna fiera attiva.
          </Typography>
        ) : (
          <List component="div" disablePadding>
            <ConfList
              confList={confList}
              fairList={fairList}
              onAction={onAction}
              onDelete={onDelete}
              openConfig={openConfig}
              tabFair={tabFair}
              deleteRequest={deleteRequest}
              setDeleteRequest={setDeleteRequest}
              fairId={fairId}
              handleClose={handleClose}
            />
          </List>
        ))}
    </Container>
  );
}
