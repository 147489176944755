import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { useIntl } from "react-intl";
import QRCodeGenerator from "qrcode";
import { ArrowBack, CloudDownload } from "@material-ui/icons";
import { common } from "../../messages";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import utils from "../../utils/utils";

export default function Operator() {
  const { bmapi, startLoading, stopLoading, notifyError } = useBmapi();
  const intl = useIntl();
  const history = useHistory();
  const [business, setBusiness] = React.useState(null);
  const [code, setCode] = useState(false);
  //const [countdown, setCountdown] = useState(60); // Countdown starts at 60 seconds

  const bsInfo = bmapi.getUserInfo().business;

  const goToHome = () => {
    history.push(MANAGER_ROUTES.HOME_MENU);
  };

  const downloadMatchList = () => {
    startLoading();
    bmapi
      .getCampusMatchListCsv({
        business: bmapi.getUserInfo().business.id,
      })
      .then((blob) => {
        utils.download(blob, "match.csv");
      })
      .catch((e) => {
        notifyError("" + e);
      })
      .finally(stopLoading);
  };

  useEffect(() => {
    let intervalId;
    let countdownIntervalId;

    const handleShowQR = (businessId) => {
      const qrCodeUrl = "MERCHANT_" + businessId;

      QRCodeGenerator.toDataURL(qrCodeUrl, { scale: 16 })
        .then(setCode)
        .catch((e) => notifyError(getErrorMessageString(e, intl)));
    };

    const fetchData = () => {
      startLoading();
      if (bmapi) {
        bmapi
          .getBusiness(bsInfo.id)
          .then((resp) => {
            setBusiness(resp);
            if (bmapi.settings.business?.operatorQrCode) {
              return bmapi.getOperatorQrCode();
            } else {
              return resp?.id;
            }
          })
          .then((qrcode) => {
            handleShowQR(qrcode);
            //setCountdown(60); // Reset the countdown after QR code refresh
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
          })
          .finally(() => {
            stopLoading();
          });
      }
    };

    // Initial load
    fetchData();

    // Set up QR code refresh interval
    {
      /*
    if (bmapi?.settings.business?.operatorQrCode) {
      intervalId = setInterval(() => {
        bmapi
          .getOperatorQrCode()
          .then((qrcode) => {
            handleShowQR(qrcode); // Update the QR code
            setCountdown(60); // Reset countdown
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
          });
      }, 60000); // Refresh every minute (60000 ms)

      // Set up countdown interval
      countdownIntervalId = setInterval(() => {
        setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000); // Decrement countdown every second
    }*/
    }

    // Cleanup intervals on component unmount
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      if (countdownIntervalId) {
        clearInterval(countdownIntervalId);
      }
    };
  }, [bmapi, bsInfo.id, intl, notifyError, startLoading, stopLoading]);

  return (
    <Container maxWidth="sm">
      {business && (
        <React.Fragment>
          <Card>
            <CardContent>
              <Typography variant="h6">
                Esporre il QR Code all&apos;utente
                {/*
                bmapi.settings.business?.operatorQrCode
                  ? "Esporre il QR Code all'utente"
                  : "MERCHANT_" + business?.id
                  */}
              </Typography>
              <img
                src={code}
                alt="QR Code"
                style={{ maxWidth: "50%", display: "block", margin: "0 auto" }}
              />
              {/*bmapi.settings.business?.operatorQrCode && (
                <Box mt={2} textAlign="center">
                  <Typography variant="body1">
                    Aggiornamento in: {countdown} secondi
                  </Typography>
                </Box>
              )*/}
              <Box mt={2}>
                <Typography variant="h6">{business.name}</Typography>
                <ListItem>
                  <ListItemText
                    primary={[
                      {
                        label: "Email aziendale",
                        content: business.manager_email || "-",
                      },
                      {
                        label: "Numero di telefono:",
                        content: business.mobile || "-",
                      },
                      {
                        label: "Cellulare Whatsapp:",
                        content: business.whatsapp_phone || "-",
                      },
                      {
                        label: "Province di competenza:",
                        content:
                          business.competence_locations?.join(", ") || "-",
                      },
                      {
                        label: "Indirizzo:",
                        content:
                          [
                            business.location.street,
                            business.location.zip,
                            business.location.city,
                            business.location.province,
                          ]?.join(", ") || "-",
                      },
                    ].map((el) => (
                      <Grid key={el.label} container spacing={2}>
                        <Grid item sm={5}>
                          {el.label}
                        </Grid>
                        <Grid item sm={7}>
                          {el.content}
                        </Grid>
                      </Grid>
                    ))}
                  />
                </ListItem>
              </Box>
              <Box my={2}>
                <Button
                  onClick={downloadMatchList}
                  startIcon={<CloudDownload />}
                >
                  Scarica match
                </Button>
              </Box>
            </CardContent>
          </Card>
          <Box my={2}>
            <Button onClick={goToHome} startIcon={<ArrowBack />}>
              {intl.formatMessage(common.backHome)}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Container>
  );
}
