import React from "react";
import { useIntl } from "react-intl";

import { TextField } from "@material-ui/core";

import { form } from "../../../messages";
import { useBmapi } from "../../../utils/bmapi-context";

export default function UrlRef({ values, handleChange }) {
  const intl = useIntl();
  const { bmapi } = useBmapi();

  return bmapi.settings.campaign?.urlRef ? (
    <TextField
      name="url_ref"
      label={intl.formatMessage(form.urlRef)}
      value={values.url_ref}
      onChange={handleChange("url_ref")}
      key="url_ref"
      fullWidth
      margin="normal"
    />
  ) : null;
}
