import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import FloatingActions, { Action } from "../../ui/FloatingActions";
import {
  Add,
  ArrowBack,
  Create,
  Delete,
  MoreVert,
  ArrowUpward,
  ArrowDownward,
  SubdirectoryArrowRight,
} from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { MANAGER_ROUTES } from "../../utils/constants";
import Title from "../../ui/Title";
import { FormattedMessage, useIntl } from "react-intl";
import { common, confirm, form } from "../../messages";
import Confirm from "../../ui/Confirm";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";

export default function ContentManagement() {
  const initialValues = (bs = {}) => ({
    title: bs.title || "",
    description: bs.description || "",
    parentId: bs.parentId || null,
    priority: bs.priority || 0, // Include priority
  });

  const intl = useIntl();
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const [anchorEl, setAnchorEl] = useState(null);
  const [contentId, setContentId] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [nestedLevel, setNestedLevel] = useState(null);
  const [contents, setContents] = useState([]);
  const [parents, setParents] = useState([]);
  const [parentsMap, setParentsMap] = useState({});
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  const [values, setValues] = useState(initialValues());

  const handleValue = useCallback((label) => {
    const updateValue = (val) => {
      setValues((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  const cmp = (a, b) => parseInt(a.priority) - parseInt(b.priority);

  const loadContents = useCallback(() => {
    startLoading();
    if (bmapi) {
      bmapi
        .getContents()
        .then((resp) => {
          if (!resp) resp = [];
          const parentsList = [];
          const pMap = {};
          for (let c of resp) {
            if (!c.parent_id) {
              parentsList.push(c);
            } else {
              let children = pMap[c.parent_id];
              if (children) {
                children.push(c);
              } else {
                children = [c];
              }
              pMap[c.parent_id] = children;
            }
          }

          setContents(resp.sort(cmp)); // Sort by priority as integers
          parentsList.sort(cmp);
          setParents(parentsList);
          setParentsMap(pMap);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  }, [bmapi, intl, notifyError, startLoading, stopLoading]);

  const getChildren = (parentId) => parentsMap[parentId];

  useEffect(() => {
    loadContents();
  }, [loadContents]);

  const handleOpen = (e, id) => {
    setAnchorEl(e.currentTarget);
    setContentId(id);
  };

  const contentDelete = () => {
    startLoading();
    setShowDeleteAlert(false);
    bmapi
      .deleteContent(contentId)
      .then(() => {
        setContentId(null);
        notifySuccess("Contenuto eliminato con successo");
        loadContents();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  const clear = () => {
    setValues(initialValues());
    setOpenDialog(false);
    setContentId(null);
    setParentId(null);
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    setSaving(true);
    console.log("----ZZZZZZZZZZZZZZZZZzz 1");
    try {
      if (!contentId) {
        const siblings = parentId
          ? contents.filter((c) => c.parent_id === parentId)
          : parents; // Get siblings of the parent
        const newPriority = siblings.length
          ? Math.max(...siblings.map((c) => parseInt(c.priority))) + 1
          : 0;
        console.log("----ZZZZZZZZZZZZZZZZZzz 2", siblings);
        //if (siblings || contents) return;
        await bmapi.createContent(
          values.title,
          values.description,
          null, // expiration
          String(newPriority), // Priority as a string
          parentId // Parent ID
        );
      } else {
        await bmapi.updateContent(
          contentId,
          values.title,
          values.description,
          null, // expiration
          String(values.priority), // Ensure priority is passed as a string
          values.parentId // Parent ID
        );
      }

      loadContents();
      notifySuccess(
        intl.formatMessage({
          id: "component.manageContent.saved",
          defaultMessage: "Contenuto salvato con successo",
        })
      );
    } catch (e) {
      notifyError(getErrorMessageString(e, intl));
    } finally {
      clear();
      setSaving(false);
    }
  };

  const handleMove = async (id, direction) => {
    const index = contents.findIndex((c) => c.id === id);
    if (index === -1) return;

    const currentContent = contents[index];
    const siblings = contents.filter(
      (c) => c.parent_id === currentContent.parent_id
    );
    const siblingIndex = siblings.findIndex((c) => c.id === id);

    const targetIndex =
      direction === "up" ? siblingIndex - 1 : siblingIndex + 1;

    if (targetIndex < 0 || targetIndex >= siblings.length) return;

    const targetContent = siblings[targetIndex];

    try {
      await Promise.all([
        bmapi.updateContent(
          currentContent.id,
          currentContent.title,
          currentContent.description,
          null,
          String(targetContent.priority),
          currentContent.parentId
        ),
        bmapi.updateContent(
          targetContent.id,
          targetContent.title,
          targetContent.description,
          null,
          String(currentContent.priority)
        ),
      ]);

      loadContents();
      notifySuccess("Contenuto spostato con successo");
    } catch (e) {
      notifyError(getErrorMessageString(e, intl));
    }
  };

  const isAtTop = (id) => {
    const content = contents.find((c) => c.id === id);
    if (!content) return false;
    const siblings = contents.filter((c) => c.parent_id === content.parent_id);
    return siblings.findIndex((c) => c.id === id) === 0;
  };

  const isAtBottom = (id) => {
    const content = contents.find((c) => c.id === id);
    if (!content) return false;
    const siblings = contents.filter((c) => c.parent_id === content.parent_id);
    return siblings.findIndex((c) => c.id === id) === siblings.length - 1;
  };

  return (
    <Container maxWidth="xl">
      <Title>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>
            <FormattedMessage
              id="navigation.manager.contentManagement"
              defaultMessage="Gestione Contenuti"
            />
          </Box>
        </Box>
      </Title>

      <Box mt={2} mb={2}>
        <Button
          component={RouterLink}
          to={MANAGER_ROUTES.HOME}
          startIcon={<ArrowBack />}
        >
          {intl.formatMessage(common.backHome)}
        </Button>
      </Box>

      {parents.length !== 0 ? (
        <Card>
          <List>
            {parents.map((c, i) => {
              const children = getChildren(c.id); // Get children of the current parent
              return (
                <React.Fragment key={c.id}>
                  {i !== 0 && <Divider />}
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography gutterBottom variant="h6">
                          {c.title} prio={c?.priority}
                        </Typography>
                      }
                      secondary={c.description}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={(e) => {
                          handleOpen(e, c.id);
                          setNestedLevel(0);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {/* Check if children exist and have length > 0 */}
                  {children && children.length > 0 && (
                    <List disablePadding>
                      {children.sort(cmp).map((child) => {
                        const subChildren = getChildren(child.id); // Get sub-children for this child
                        return (
                          <React.Fragment key={child.id}>
                            <ListItem style={{ paddingLeft: 32 }}>
                              <ListItemText
                                primary={
                                  <Typography variant="subtitle1">
                                    {child.title} prio={child.priority}
                                  </Typography>
                                }
                                secondary={child.description}
                              />
                              <ListItemSecondaryAction>
                                <IconButton
                                  onClick={(e) => {
                                    handleOpen(e, child.id);
                                    setNestedLevel(1);
                                  }}
                                >
                                  <MoreVert />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                            {/* Check if sub-children exist and have length > 0 */}
                            {subChildren && subChildren.length > 0 && (
                              <List disablePadding>
                                {subChildren.sort(cmp).map((subChild) => (
                                  <ListItem
                                    key={subChild.id}
                                    style={{ paddingLeft: 64 }}
                                  >
                                    <ListItemText
                                      primary={
                                        <Typography variant="body2">
                                          {subChild.title} prio=
                                          {subChild.priority}
                                        </Typography>
                                      }
                                      secondary={subChild.description}
                                    />
                                    <ListItemSecondaryAction>
                                      <IconButton
                                        onClick={(e) => {
                                          handleOpen(e, subChild.id);
                                          setParentId(null); // Reset parentId for 3rd level
                                          setNestedLevel(2);
                                        }}
                                      >
                                        <MoreVert />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                ))}
                              </List>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </List>
                  )}
                </React.Fragment>
              );
            })}
          </List>
        </Card>
      ) : (
        <Card>
          <CardContent style={{ padding: 16 }}>
            Nessun contenuto presente
          </CardContent>
        </Card>
      )}

      <Menu
        key={contentId}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setContentId(null);
        }}
        keepMounted
        onClick={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            const selectedContent = contents.find((c) => c.id === contentId);
            if (selectedContent) {
              setValues(initialValues(selectedContent)); // Include priority
            }
            setOpenDialog(true);
          }}
        >
          <ListItemIcon>
            <Create />
          </ListItemIcon>
          <Typography variant="inherit">Modifica</Typography>
        </MenuItem>
        {nestedLevel !== 2 && (
          <MenuItem
            onClick={() => {
              setParentId(contentId); // Set parentId for sub-element creation
              setContentId(null); // Reset contentId to ensure creation
              setValues(initialValues()); // Reset form for new item creation
              setOpenDialog(true);
            }}
          >
            <ListItemIcon>
              <SubdirectoryArrowRight />
            </ListItemIcon>
            <Typography variant="inherit">Aggiungi sotto elemento</Typography>
          </MenuItem>
        )}
        {!isAtTop(contentId) && (
          <MenuItem onClick={() => handleMove(contentId, "up")}>
            <ListItemIcon>
              <ArrowUpward />
            </ListItemIcon>
            <Typography variant="inherit">Sposta sopra</Typography>
          </MenuItem>
        )}
        {!isAtBottom(contentId) && (
          <MenuItem onClick={() => handleMove(contentId, "down")}>
            <ListItemIcon>
              <ArrowDownward />
            </ListItemIcon>
            <Typography variant="inherit">Sposta sotto</Typography>
          </MenuItem>
        )}
        {!getChildren(contentId)?.length && (
          <MenuItem
            onClick={() => {
              setShowDeleteAlert(true);
            }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <Typography variant="inherit">Elimina</Typography>
          </MenuItem>
        )}
      </Menu>

      <Confirm
        open={showDeleteAlert}
        onConfirm={() => {
          contentDelete(contentId);
        }}
        onCancel={() => {
          setContentId(null);
          setShowDeleteAlert(false);
        }}
        text={intl.formatMessage(confirm.deleteElement)}
      />

      <Dialog
        open={openDialog}
        fullWidth
        PaperProps={{
          style: {
            margin: 10,
            width: "100vw",
          },
        }}
      >
        <DialogTitle>
          {contentId ? (
            <FormattedMessage id="common.modify" defaultMessage="Modifica" />
          ) : (
            <FormattedMessage id="common.add" defaultMessage="Aggiungi" />
          )}
        </DialogTitle>
        <DialogContent style={{ padding: 15 }}>
          <form onSubmit={handleCreate}>
            <TextField
              autoFocus
              margin="normal"
              label={intl.formatMessage(form.title)}
              value={values.title}
              onChange={handleValue("title")}
              required
              fullWidth
            />
            <TextField
              margin="normal"
              label={intl.formatMessage(form.description)}
              value={values.description}
              onChange={handleValue("description")}
              required
              fullWidth
            />
            <DialogActions>
              <Button onClick={clear} disabled={saving} variant="outlined">
                <FormattedMessage id="common.cancel" defaultMessage="Annulla" />
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={saving}
              >
                {contentId ? (
                  <FormattedMessage id="common.save" defaultMessage="Salva" />
                ) : (
                  <FormattedMessage id="common.create" defaultMessage="Crea" />
                )}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <FloatingActions>
        <Action
          icon={<Add />}
          label="Aggiungi contenuto"
          action={() => {
            setParentId(null); // For root-level item
            setOpenDialog(true);
          }}
        />
      </FloatingActions>
    </Container>
  );
}
