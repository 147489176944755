import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  ListItem,
  Typography,
} from "@material-ui/core";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { useIntl } from "react-intl";
import QRCodeGenerator from "qrcode";
import { ArrowBack } from "@material-ui/icons";
import { common } from "../../messages";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function UserData() {
  const { bmapi, startLoading, stopLoading, notifyError } = useBmapi();
  const intl = useIntl();
  const history = useHistory();
  const [business, setBusiness] = React.useState(null);
  const [code, setCode] = useState(false);
  const [countdown, setCountdown] = useState(60); // Countdown starts at 60 seconds

  const bsInfo = bmapi.getUserInfo().business;
  console.log(code, countdown);

  const goToHome = () => {
    history.push(MANAGER_ROUTES.HOME_MENU);
  };

  useEffect(() => {
    let intervalId;
    let countdownIntervalId;

    const handleShowQR = (businessId) => {
      const qrCodeUrl = businessId;

      QRCodeGenerator.toDataURL(qrCodeUrl, { scale: 16 })
        .then(setCode)
        .catch((e) => notifyError(getErrorMessageString(e, intl)));
    };

    const fetchData = () => {
      startLoading();
      if (bmapi) {
        bmapi
          .getBusiness(bsInfo.id)
          .then((resp) => {
            setBusiness(resp);
            if (bmapi.settings.business?.operatorQrCode) {
              return bmapi.getOperatorQrCode();
            } else {
              return resp?.id;
            }
          })
          .then((qrcode) => {
            handleShowQR(qrcode);
            setCountdown(60); // Reset the countdown after QR code refresh
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
          })
          .finally(() => {
            stopLoading();
          });
      }
    };

    // Initial load
    fetchData();

    // Set up QR code refresh interval
    if (bmapi?.settings.business?.operatorQrCode) {
      intervalId = setInterval(() => {
        bmapi
          .getOperatorQrCode()
          .then((qrcode) => {
            handleShowQR(qrcode); // Update the QR code
            setCountdown(60); // Reset countdown
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
          });
      }, 60000); // Refresh every minute (60000 ms)

      // Set up countdown interval
      countdownIntervalId = setInterval(() => {
        setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000); // Decrement countdown every second
    }

    // Cleanup intervals on component unmount
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      if (countdownIntervalId) {
        clearInterval(countdownIntervalId);
      }
    };
  }, [bmapi, bsInfo.id, intl, notifyError, startLoading, stopLoading]);

  return (
    <Container maxWidth="sm">
      {business && (
        <React.Fragment>
          <Card>
            <CardContent>
              <Typography variant="h6">
                Utenti che hanno effettuato il match
              </Typography>
              <Box mt={2}>
                <ListItem></ListItem>
              </Box>
            </CardContent>
          </Card>
          <Box my={2}>
            <Button onClick={goToHome} startIcon={<ArrowBack />}>
              {intl.formatMessage(common.backHome)}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Container>
  );
}
