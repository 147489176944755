import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";

import { TextField } from "@material-ui/core";
import { ChromePicker } from "react-color"; // Ensure react-color is installed

import { form } from "../../../messages";

export default function CustomData({ value, handleChange, customDataConfig }) {
  const intl = useIntl();

  const [customDataMap, setCustomDataMap] = useState(() => {
    try {
      return value ? JSON.parse(value) : {};
    } catch {
      return {};
    }
  });

  const [pickerOpen, setPickerOpen] = useState(null); // Internal state for color picker UI

  useEffect(() => {
    try {
      const parsed = value ? JSON.parse(value) : {};
      setCustomDataMap(parsed);
    } catch {
      setCustomDataMap({});
    }
  }, [value]);

  const updateCustomDataField = (name, value) => {
    const updatedMap = {
      ...customDataMap,
      [name]: value,
    };

    setCustomDataMap(updatedMap);
    handleChange({
      target: { value: JSON.stringify(updatedMap) },
    });
  };

  if (!customDataConfig || !Array.isArray(customDataConfig)) {
    return null;
  }

  return (
    <div style={{ marginBottom: "16px" }}>
      {/* Single label for the group */}
      <label style={{ display: "block", marginBottom: "8px" }}>
        {intl.formatMessage(form.customData)}
      </label>

      {/* Flexbox container */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap", // Wraps items to the next line when needed
          gap: "16px", // Space between items
          alignItems: "flex-start", // Optional: Aligns items on the vertical axis
        }}
      >
        {customDataConfig.map((field) => {
          switch (field.type) {
            case "colorPicker":
              return (
                <div
                  key={field.name}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  {/* Color preview */}
                  <div
                    style={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: customDataMap[field.name] || "#ffffff",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setPickerOpen(
                        pickerOpen === field.name ? null : field.name
                      )
                    }
                  />

                  {/* Popup color picker */}
                  {pickerOpen === field.name && (
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 10,
                        top: "50px",
                        left: 0,
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                        background: "#fff",
                        borderRadius: "4px",
                      }}
                    >
                      <ChromePicker
                        color={customDataMap[field.name] || "#ffffff"}
                        onChange={(color) =>
                          updateCustomDataField(field.name, color.hex)
                        }
                        disableAlpha
                      />
                      <button
                        style={{
                          margin: "8px auto",
                          display: "block",
                          padding: "4px 8px",
                          cursor: "pointer",
                          backgroundColor: "#007bff",
                          color: "#fff",
                          border: "none",
                          borderRadius: "4px",
                        }}
                        onClick={() => setPickerOpen(null)}
                      >
                        Close
                      </button>
                    </div>
                  )}
                </div>
              );

            case "textField":
              return (
                <TextField
                  key={field.name}
                  name={field.name}
                  label={field.label}
                  value={customDataMap[field.name] || ""}
                  onChange={(e) =>
                    updateCustomDataField(field.name, e.target.value)
                  }
                  fullWidth={false} // Allow it to take only the required width
                  margin="normal"
                  style={{ width: "160px" }}
                />
              );

            default:
              return null; // Render nothing for unsupported field types
          }
        })}
      </div>
    </div>
  );
}
